<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapEuropeanUnionRegulationsItems"
    :api="{
      moduleId: 'pk',
      modulesWithName: 'custom_query_european_union_acts_with_names',
      modules: 'module_european_union_regulations_header',
      favoriteHated: 'favorite_and_hated_european_union_regulations_header',
      favoriteHatedResponse: 'favorite_and_hateful_european_union_regulations_list',
      bookmarks: 'bookmarks/fetchEuropeanUnionRegulations',
    }"
    #default="{items}"
  >
    <european-union-regulations-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import EuropeanUnionRegulationsList from '../../EuropeanUnionRegulations/EuropeanUnionRegulationsList';
import { mapEuropeanUnionRegulationsItems } from '../../EuropeanUnionRegulations/mapEuropeanUnionRegulationsItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    EuropeanUnionRegulationsList,
  },
  metaInfo() {
    return {
      title: 'Monitoring Rozporządzeń UE',
    };
  },
  data() {
    return {
      mapEuropeanUnionRegulationsItems: mapEuropeanUnionRegulationsItems,
    };
  },
};
</script>
